
.brand-container{
  background-color: white;
  .link{
    display: flex;
  }
  .icon{
    margin-right: 0.5px;
    width: 20px;
    height: 20px;
  }
  .makeStyles-imageTitle-7{
    font-weight:bolder ;
  }
  .makeStyles-root-9{
    justify-content: space-around;
  }
}
@media screen and (max-width: 600px){
  .margin-images{
    margin-bottom: 20px !important;
  }
  .margin-images:last-child{
    margin-bottom: 0 !important;
  }
}

